import React from 'react';

const PrimaryButton = ({ title, onClick, disabled, spinner = true, disabledTitle, style }) => {
    return (
        <button
            onClick={disabled ? null : onClick}
            disabled={disabled}
            className={`primary-button-container ${disabled ? 'disabled' : ''}`}
            style={style}
        >
            {disabled && spinner && (
                <div className="spinner">
                    <div className="circle"></div>
                </div>
            )}

            <span>{disabled ? disabledTitle : title}</span>
        </button>
    );
};

export default PrimaryButton;