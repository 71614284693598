import React from 'react';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import { Visibility, VisibilityOff } from '@mui/icons-material';

const LoginTextInput = ({
  name,
  label,
  value,
  onChange,
  error,
  password,
  showPassword,
  placeholder,
  togglePasswordVisibility,
  rows,
  className,
  startIcon, // New prop for the start icon
  style = {},
  maxLength,
}) => {
  return (
    <div className="inputFields">
      <TextField
        name={name}
        className={className}
        variant="outlined"
        label={label}
        type={password ? (showPassword ? 'text' : 'password') : 'text'}
        value={value}
        onChange={onChange}
        error={!!error}
        helperText={error}
        multiline={false}
        fullWidth
        rows={rows || 1}
        style={style}
        placeholder={placeholder}
        InputProps={{
          startAdornment: startIcon && (
            <InputAdornment position="start">
              {startIcon}
            </InputAdornment>
          ),
          endAdornment: password && (
            <InputAdornment position="end">
              <IconButton onClick={togglePasswordVisibility}>
                {showPassword ? <Visibility /> : <VisibilityOff />}
              </IconButton>
            </InputAdornment>
          ),
          inputProps: {
            maxLength: maxLength,
          },
        }}
      />
    </div>
  );
};

export default LoginTextInput;