import { Box, Button, CircularProgress, Modal, Paper, IconButton, InputAdornment, Table, TableBody, TableCell, TableContainer, TableFooter, TableHead, TableRow, TextField, Typography, Container } from '@mui/material';
import React, { useEffect, useState } from 'react';
import '../../saas/screens/clientManagement.scss'
import { API } from '../../utils/Api';
import axios from 'axios';
import { toast } from 'react-toastify';
import EditIcon from "@mui/icons-material/Edit";
import LockResetIcon from '@mui/icons-material/LockReset';
import Images from '../../utils/Images';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { motion } from 'framer-motion';

function ClientManagement() {

    const token = localStorage.getItem("token");
    const [open, setOpen] = useState(false);
    const handleClose = () => setOpen(false);
    const [openProfile, setOpenProfile] = useState(false);
    const handleProfileClose = () => setOpenProfile(false);
    const [clients, setClients] = useState();
    const [loading, setLoading] = useState(false);
    const [loading2, setLoading2] = useState(false);
    const [clientDetails, setClientDetails] = useState();
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false)

    const [updatePassword, setUpdatePassword] = useState({
        old_password: '',
        password: '',
        confirmPassword: '',
        // showPassword : false,
        // showConfirmPassword: false
    });


    const initialValues = {
        mobile: clients?.mobile || 'Loading...',
        email: clients?.email || 'Loading...',
        address: clients?.address || 'Loading...',
        state: clients?.state || 'Loading...',
        city: clients?.city || 'Loading...',
        pincode: clients?.pincode || 'Loading...'
    }

    // const validationSchema = Yup.object({
    //     mobile: Yup.string()
    //         .required("Phone Number is required!")
    //         .min(10, "Phone number must be at least 10 characters")
    //         .matches(/^[6-9][0-9]{9}$/, 'Enter a valid Phone number starting with 6, 7, 8, or 9'),
    //     email: Yup.string().required('Email is required!'),
    //     state: Yup.string()
    //         .required('State is required!')
    //         .matches(/^[A-Za-z\s'-]+$/, 'State can only contain letters, spaces, hyphens (-), or apostrophes (\')')
    //         .matches(/^[A-Za-z]/, 'First character can only consist of characters [a-z] or [A-Z]')
    //         .min(2, 'Name must be at least 2 characters long'),
        
    // });

    const validationSchema = Yup.object({
        mobile: Yup.string()
            .required("Mobile Number is required!")
            .min(10, "Mobile number must be at least 10 characters")
            .matches(/^[6-9][0-9]{9}$/, 'Enter a valid Phone number starting with 6, 7, 8, or 9'),
        email: Yup.string()
            .required('Email is required!')
            .trim() // Remove leading and trailing whitespace
            .email('Enter a valid email address'), // Validate email format
        address: Yup.string()
            .required('Address is required!')
            .trim()
            .matches(/^[a-zA-Z0-9\s\/\\,.-]+$/, 'Address can only contain letters, numbers, spaces, forward slashes, backslashes, commas, and full stops')
            .min(2, 'Address must be at least 2 characters long'),
        pincode: Yup.string()
            .required('Pincode is required')
            .trim()
            .matches(/^[0-9]+$/, 'Pincode must contain only numeric values')
            .matches(/^\d{6}$/, 'Invalid pinocde or it does not exists'),
        city: Yup.string()
            .required('City is required')
            .trim()
            .min(2, 'City must be at least 2 characters long')
            .matches(/^[A-Za-z\s-]+$/, 'City must not contain special characters'),
        state: Yup.string()
            .required('State is required!')
            .matches(/^[A-Za-z\s'-]+$/, 'State can only contain letters, spaces, hyphens (-), or apostrophes (\')')
            .matches(/^[A-Za-z]/, 'First character can only consist of characters [a-z] or [A-Z]')
            .min(2, 'State must be at least 2 characters long'),
        
    });

    let validationSchemaPass = Yup.object({
        old_password: Yup.string()
            .required('Current password is required')
            .trim()
            .min(8, 'Password must be at least 8 characters long'),
        password: Yup.string()
            .required('New password is required')
            .trim()
            .min(8, 'New password must be at least 8 characters long'),
        confirmPassword: Yup.string()
            .required('Confirm password is required')
            .trim()
            .min(8, 'New password must be at least 8 characters long'),
    })


    const columns = [
        { id: "name", label: "Name", minWidth: 140 },
        { 
            id: "mobile",
            label: "Mobile",
            minWidth: 120,
            align: "left",
            format: (value) => value.toLocalString("en-US")
        },
        { id: "email", label: "Email", minWidth: 100, align: "left" },
        {
            id: "address",
            label: "Address",
            minWidth: 150,
            align: "left",
            format: (value) => value.toLocalString("en-US")
        },
        {
            id: "pincode",
            label: "Pincode",
            minWidth: 100,
            align: "left",
            format: (value) => value.toLocalString("en-US")
        },
        {
            id: "city",
            label: "City",
            minWidth: 100,
            align: "left",
            format: (value) => value.toFixed(2),
        },
        {
            id: "state",
            label: "State",
            minWidth: 100,
            align: "left",
            format: (value) => value.toFixed(2),
        },
        {
            id: "action",
            label: "Actions",
            minWidth: 150,
            align: "left",
        }

    ]

    const getClientData = async () => {
        try {
            const config = {
                headers : {
                    Authorization : `${token}`
                }
            };
            setLoading(true)
            const response = await axios.get(`${API.BASE_URL}client/profile`, config);
            if(response.data.status === true) {
                setLoading(false);
                setClients(response.data.data.client);
            } else { 
                toast.error(response.data.message, {
                    position: "top-right",
                    autoClose: 3000,
                    theme: 'colored'
                })
            }
        } catch (error) {
            let errorMessage = "An Error Occurred While Fetching Client's Records"
            if(error.response && error.response.data) {
                errorMessage = error.response.data.message;
            }
            setLoading(false);
            toast.error(errorMessage, {
                position: "top-right",
                autoClose: 3000,
                theme: "colored",
            });
        }
    }

    const handleOpenPasswordModal = () => {
        setOpen(true)
    }

    const handleOpenProfileModal = (clientData) => {
        setOpenProfile(true);
        setClientDetails(clientData);
    }

    const handleUpdateProfile = async (event) => {
        event.preventDefault();
        setLoading2(true);
        let updateData = {
            mobile: clientDetails.mobile,
            email: clientDetails.email,
            address: clientDetails.address,
            pincode: clientDetails.pincode,
            city: clientDetails.city,
            state: clientDetails.state
        }
        try {
            const response = await axios.put(`${API.BASE_URL}client/update`, updateData, {
                headers: {
                    Authorization: `${token}`,
                },
            })
            getClientData();
            setLoading2(false);
            handleProfileClose();
            toast.success("Profile Updated Successfully");
        } catch (error) {
            console.log("Error updating the profile", error);
            setLoading2(false);
            toast.error("Failed to update the profile")
        }
    }


    const handleSubmitProfile = async (values) => {

        console.log("AVlues:", values);
        
        let updateProfileData = {
            mobile: values.mobile,
            email: values.email,
            address: values.address,
            state:  values.state,
            city: values.city,
            pincode: values.pincode
        }
        try {
            const response = await axios.put(`${API.BASE_URL}client/update`, updateProfileData, {
                headers: {
                    Authorization: `${token}`,
                },
            })
            getClientData();
            setLoading2(false);
            handleProfileClose();
            toast.success("Profile Updated Successfully");
        } catch (error) {
            console.log("Error updating the profile", error.request.response);
            setLoading2(false);
            // toast.error( error.request.response);

                 // Parse the error response JSON
                // const errorResponse = JSON.parse(error.request.response);
                
                // // Extract the error message related to the mobile number field
                // const mobileNumberError = errorResponse.data.form_error.match(/The Mobile Number field (?:is required|cannot exceed 10 characters in length)/i);
                // // const emailError = errorResponse.data.form_error.match(/The Email Address field is required/i);

                // // Display the error message in the toast
                // if (mobileNumberError) {
                //     toast.error(mobileNumberError[0]);
                // } else {
                //     // If there's no specific error message for the mobile number field, display a generic error
                //     toast.error("An error occurred while updating the profile.");
                // }

        }

    }


    const handleUpdatePassword = async (values) => {
        console.log("hello", updatePassword);
        // event.preventDefault();
        setLoading(true);
        let updatedpassword = {
            old_password: values.old_password,
            password: values.password
        }
        try {
            const response = await axios.put(`${API.BASE_URL}client/password`,updatedpassword, {
                headers: {
                    Authorization: `${token}`,
                },
            })
            setLoading(false);
            getClientData();
            // handleClose();
            toast.success("Password Changed Successfully");
        } catch (error) {
            console.log("Error Changing the password", error);
            setLoading(false);
            toast.error("Failed To Change Password")
        }

    }

    const generateRandomPassword = () => {
        const length = 10; // Adjust the length of the generated password as needed
        const charset = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789@#$&";

        let password = "";
        for (let i = 0; i < length; i++) {
            const randomIndex = Math.floor(Math.random() * charset.length);
            password += charset[randomIndex];
        }
        console.log("Generated password: ", password);

        setUpdatePassword({
            ...updatePassword,
            password: password,
            confirmPassword: password
        });
    };

    const togglePasswordVisibility = () => {
        // setUpdatePassword({
        //     ...updatePassword,
        //     showPassword: !updatePassword.showPassword
        // });
        setShowPassword(!showPassword);
    };

    const toggleConfirmPasswordVisibility = () => {
        // setUpdatePassword({
        //     ...updatePassword,
        //     showConfirmPassword: !updatePassword.showConfirmPassword
        // });
        setShowConfirmPassword(!showConfirmPassword);
    };

    useEffect(() => {
        getClientData();
    }, [])


  return (
    <div className='clientMain'>

        <Container maxWidth="xl" sx={{display:'flex'}}>

            <Paper className='profileSection'>

                {/* <Typography className='profileText text-center'>My Profile</Typography> */}
                <div style={{backgroundColor: '#1E9CED', marginBottom: '15px', padding: '12px', borderTopLeftRadius: '12px', borderTopRightRadius: '12px' }}>
                    <Typography className='profileText'>PROFILE</Typography>
                </div>
                <Container className='imgAndProfileSection'>
                    <img className="profileSmallMob bg-primary" src={Images.profileSmall} style={{height: '80px', width: '80px'}} alt='profile-icon' />
                    <div>
                        <Typography className='profileName'>{clients?.name}</Typography>
                    </div>
                </Container>

                <Formik initialValues={initialValues} onSubmit={handleSubmitProfile} validationSchema={validationSchema} enableReinitialize={true} >
                    {({ handleChange, handleBlur, handleSubmit, values, errors, touched }) => (
                        <Container>
                            <div className='profileTextSection'>
                                <TextField  
                                    label="Mobile Number"
                                    onChange={handleChange('mobile')}
                                    id='outlined-size-small'
                                    onBlur={handleBlur('mobile')}
                                    size='small'
                                    type='number'
                                    placeholder='Mobile Number'
                                    className='profileTextField'
                                    value={values?.mobile}
                                    required
                                    // onChange={(e) => setClients({...clients, mobile: e.target.value})}
                                    // onChange={(e) => {
                                    //     const newValue = Math.max(parseInt(e.target.value), 0);
                                    //     setClients({...clients, mobile: newValue});
                                    // }}
                                />
                                <div style={{}}>
                                    {
                                        errors.mobile ?
                                            <div>
                                                {
                                                    touched.mobile ?
                                                        <Typography style={{ color: 'red', fontSize: '10px' }}>{errors.mobile}</Typography> : <></>
                                                }
                                            </div> :
                                            <div></div>
                                    }
                                </div>

                                <TextField 
                                    label="Email"
                                    onChange={handleChange('email')}
                                    placeholder='Email Address'
                                    id='outlined-size-small'
                                    size='small'
                                    className='profileTextField'
                                    onBlur={handleBlur('email')}
                                    value={values?.email}
                                    required
                                    // onChange={(e) => setClients({...clients, email: e.target.value})}
                                />
                                <div style={{}}>
                                    {
                                        errors.email ?
                                            <div>
                                                {
                                                    touched.email ?
                                                        <Typography style={{ color: 'red', fontSize: '10px' }}>{errors.email}</Typography> : <></>
                                                }
                                            </div> :
                                            <div></div>
                                    }
                                </div>

                                <TextField 
                                    label="Address"
                                    onChange={handleChange('address')}
                                    onBlur={handleBlur('address')}
                                    placeholder='Address'
                                    id='outlined-size-small'
                                    size='small'
                                    multiline
                                    rows={3} // Adjust the number of rows as needed
                                    className='profileTextField'
                                    value={values?.address}
                                    required
                                />
                                <div style={{}}>
                                    {
                                        errors.address ?
                                            <div>
                                                {
                                                    touched.address ?
                                                        <Typography style={{ color: 'red', fontSize: '10px' }}>{errors.address}</Typography> : <></>
                                                }
                                            </div> :
                                            <div></div>
                                    }
                                </div>
                                
                                <div className='d-flex' style={{justifyContent:'space-between'}}>
                                    <TextField 
                                        label="Pincode"
                                        placeholder='Pincode'
                                        onChange={handleChange('pincode')}
                                        onBlur={handleBlur('pincode')}
                                        id='outlined-size-small'
                                        size='small'
                                        className='profileTextField'
                                        type='number'
                                        value={values?.pincode}
                                        sx={{width:"48%"}}
                                        required
                                        // onChange={(e) => {
                                        //     const newValue = Math.max(parseInt(e.target.value), 0);
                                        //     setClients({...clients, pincode: e.target.value})
                                        // }}
                                    />
                                    <div style={{}}>
                                        {
                                            errors.pincode ?
                                                <div>
                                                    {
                                                        touched.pincode ?
                                                            <Typography style={{ color: 'red', fontSize: '10px' }}>{errors.pincode}</Typography> : <></>
                                                    }
                                                </div> :
                                                <div></div>
                                        }
                                    </div>

                                    <TextField 
                                        label="City"
                                        onChange={handleChange('city')}
                                        onBlur={handleBlur('city')}
                                        placeholder='City'
                                        sx={{width:'48%'}}
                                        id='outlined-size-small'
                                        size='small'
                                        className='profileTextField'
                                        value={values?.city}
                                        required
                                    />
                                    <div style={{}}>
                                        {
                                            errors.city ?
                                                <div>
                                                    {
                                                        touched.city ?
                                                            <Typography style={{ color: 'red', fontSize: '10px' }}>{errors.city}</Typography> : <></>
                                                    }
                                                </div> :
                                                <div></div>
                                        }
                                    </div>
                                </div>

                                <TextField 
                                    // helperText="this field is required"
                                    label="State"
                                    onChange={handleChange('state')}
                                    onBlur={handleBlur('state')}
                                    placeholder='State'
                                    id='outlined-size-small'
                                    size='small'
                                    className='profileTextField'
                                    value={values?.state}
                                    required
                                />
                                <div style={{}}>
                                    {
                                        errors.state ?
                                            <div>
                                                {
                                                    touched.state ?
                                                        <Typography style={{ color: 'red', fontSize: '10px', fontSize: '10px' }}>{errors.state}</Typography> : <></>
                                                }
                                            </div> :
                                            <div></div>
                                    }
                                </div>

                            </div>

                            <div className='profileBothButtons'>
                                <Button variant='contained' onClick={handleSubmit} sx={{borderRadius: '18px', width: '100%', backgroundColor: '#1E9CED',marginBottom: '20px' }} >
                                    {loading2 && (
                                        <CircularProgress
                                            className="loader"
                                            size={24}
                                            style={{ marginLeft: loading2 ? 8 : 0 }}
                                        />
                                    )}
                                    <span style={{ visibility: loading2 ? "hidden" : "visible" }}>Update Profile</span>
                                </Button>
                                {/* <Button variant='contained' onClick={handleOpenPasswordModal}>Change Password</Button> */}
                            </div>
                        </Container>
                    )}
                </Formik>
            </Paper>

            {/* <----------------- Update Passowrd Form ----------------> */}
            {/* <Paper className='updatePassowrdForm'>
                <Formik initialValues={updatePassword} onSubmit={handleUpdatePassword} validationSchema={validationSchemaPass} enableReinitialize={true} >
                    {({ handleChange, handleBlur, handleSubmit, values, errors, touched }) => (
                        <div className="resetPasswordModal">
                            <div style={{backgroundColor: '#1E9CED', margin: '-24px', marginBottom: '14px', padding: '10px', paddingBottom: '0',borderTopRightRadius: '12px', borderTopLeftRadius: '12px' }}>
                                <Typography className="addRiderText" sx={{marginLeft: '5px' }}>Reset Password</Typography>
                            </div>
                            <TextField
                                label="Current Password"
                                id="outlined-size-small"
                                size="small"
                                className="restPasswordText"
                                // onChange={e => setUpdatePassword({...updatePassword, old_password: e.target.value})}
                                onChange={handleChange('old_password')}
                                onBlur={handleBlur('old_password')}
                                sx={{marginTop: '10px'}}
                            />
                            <div style={{}}>
                                    {
                                        errors.old_password ?
                                            <div>
                                                {
                                                    touched.old_password ?
                                                        <Typography style={{ color: 'red', fontSize: '10px' }}>{errors.old_password}</Typography> : <></>
                                                }
                                            </div> :
                                            <div></div>
                                    }
                            </div>
                                
                            <div className='restPasswordTextSection'>
                                <Typography onClick={generateRandomPassword} sx={{fontSize: '10px', color: 'blue', textDecoration: 'underline', cursor: 'pointer', textAlign: 'right' }}>Generate Password</Typography>
                                <TextField
                                    label="New Password"
                                    id="outlined-size-small"
                                    size="small"
                                    name='password'
                                    value={values.password}
                                    type={updatePassword.showPassword ? 'text' : 'password'}
                                    className="restPasswordText"
                                    onChange={handleChange('password')}
                                    onBlur={handleBlur('password')}
                                    // onChange={e => setUpdatePassword({...updatePassword, password: e.target.value})}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton onClick={togglePasswordVisibility} edge="end">
                                                    {updatePassword.showPassword ? <VisibilityOff /> : <Visibility />}
                                                </IconButton>
                                            </InputAdornment>
                                        )
                                    }}
                                />
                                <div style={{}}>
                                        {
                                            errors.password ?
                                                <div>
                                                    {
                                                        touched.password ?
                                                            <Typography style={{ color: 'red', fontSize: '10px' }}>{errors.password}</Typography> : <></>
                                                    }
                                                </div> :
                                                <div></div>
                                        }
                                </div>
                            </div>

                            <div className='restPasswordTextSection-1' style={{marginTop: '20px'}}>
                                <TextField
                                    label="Confirm Password"
                                    id="outlined-size-small"
                                    size="small"
                                    type={updatePassword.showConfirmPassword ? 'text' : 'password'}
                                    value={values.password}
                                    className="restPasswordText"
                                    onChange={handleChange('confirmPassword')}
                                    onBlur={handleBlur('confirmPassword')}
                                    // onChange={e => setUpdatePassword({...updatePassword, confirmPassword: e.target.value})}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton onClick={toggleConfirmPasswordVisibility} edge="end">
                                                    {updatePassword.showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                                                </IconButton>
                                            </InputAdornment>
                                        )
                                    }}
                                />
                                <div style={{}}>
                                        {
                                            errors.confirmPassword ?
                                                <div>
                                                    {
                                                        touched.confirmPassword ?
                                                            <Typography style={{ color: 'red', fontSize: '10px' }}>{errors.confirmPassword}</Typography> : <></>
                                                    }
                                                </div> :
                                                <div></div>
                                        }
                                </div>
                            </div>

                            <Button variant='contained' sx={{marginTop: '25px', borderRadius: '18px', backgroundColor: '#1E9CED' }} disabled={ updatePassword.password !== updatePassword.confirmPassword } onClick={handleSubmit} >Update Password</Button>
                        </div>
                    )}
                </Formik>
            </Paper> */}

                <Paper className='resetPasswordSection'>
                    <div className='resetPassTextSection'>
                        <Typography className='resetPassText'>Reset Password</Typography>
                    </div>
                    <Formik initialValues={updatePassword} onSubmit={handleUpdatePassword} validationSchema={validationSchemaPass} enableReinitialize={true} >
                        {({ handleChange, handleBlur, handleSubmit, values, errors, touched }) => (
                            <Container>
                                
                                <TextField
                                    label="Current Password"
                                    id="outlined-size-small"
                                    size="small"
                                    className="restPasswordText"
                                    // onChange={e => setUpdatePassword({...updatePassword, old_password: e.target.value})}
                                    onChange={handleChange('old_password')}
                                    onBlur={handleBlur('old_password')}
                                    sx={{marginTop: '10px'}}
                                    required
                                />
                                <div style={{}}>
                                        {
                                            errors.old_password ?
                                                <div>
                                                    {
                                                        touched.old_password ?
                                                            <Typography style={{ color: 'red', fontSize: '10px' }}>{errors.old_password}</Typography> : <></>
                                                    }
                                                </div> :
                                                <div></div>
                                        }
                                </div>
                                    
                                <div className='restPasswordTextSection'>
                                    <Typography onClick={generateRandomPassword} sx={{fontSize: '10px', color: 'blue', textDecoration: 'underline', cursor: 'pointer', textAlign: 'right' }}>Generate Password</Typography>
                                    <TextField
                                        label="New Password"
                                        id="outlined-size-small"
                                        size="small"
                                        name='password'
                                        value={values.password}
                                        type={showPassword ? 'text' : 'password'}
                                        className="restPasswordText"
                                        onChange={handleChange('password')}
                                        onBlur={handleBlur('password')}
                                        required
                                        // onChange={e => setUpdatePassword({...updatePassword, password: e.target.value})}
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <IconButton onClick={togglePasswordVisibility} edge="end">
                                                        {showPassword ? <VisibilityOff /> : <Visibility />}
                                                    </IconButton>
                                                </InputAdornment>
                                            )
                                        }}
                                    />
                                    <div style={{}}>
                                            {
                                                errors.password ?
                                                    <div>
                                                        {
                                                            touched.password ?
                                                                <Typography style={{ color: 'red', fontSize: '10px' }}>{errors.password}</Typography> : <></>
                                                        }
                                                    </div> :
                                                    <div></div>
                                            }
                                    </div>
                                </div>

                                <div className='restPasswordTextSection-1' style={{marginTop: '20px'}}>
                                    <TextField
                                        label="Confirm Password"
                                        id="outlined-size-small"
                                        size="small"
                                        type={showConfirmPassword ? 'text' : 'password'}
                                        value={values.confirmPassword}
                                        className="restPasswordText"
                                        onChange={handleChange('confirmPassword')}
                                        onBlur={handleBlur('confirmPassword')}
                                        // onChange={e => setUpdatePassword({...updatePassword, confirmPassword: e.target.value})}
                                        required
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <IconButton onClick={toggleConfirmPasswordVisibility} edge="end">
                                                        {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                                                    </IconButton>
                                                </InputAdornment>
                                            )
                                        }}
                                    />
                                    <div style={{}}>
                                            {
                                                errors.confirmPassword ?
                                                    <div>
                                                        {
                                                            touched.confirmPassword ?
                                                                <Typography style={{ color: 'red', fontSize: '10px' }}>{errors.confirmPassword}</Typography> : <></>
                                                        }
                                                    </div> :
                                                    <div></div>
                                            }
                                    </div>
                                </div>

                                <Button variant='contained' sx={{marginTop: '25px', borderRadius: '18px', backgroundColor: '#1E9CED', width: '100%', marginBottom:'20px' }} disabled={ updatePassword.password !== updatePassword.confirmPassword } onClick={handleSubmit} >Update Password</Button>
                            </Container>        
                        )}
                    </Formik>
                </Paper>

        </Container>
        
        {/* <-------------- Change Password Modal ---------------> */}
        {/* <Modal 
            open={open}
            onClose={handleClose}
        >
            <Formik initialValues={updatePassword} onSubmit={handleUpdatePassword} validationSchema={validationSchemaPass} enableReinitialize={true} >
            {({ handleChange, handleBlur, handleSubmit, values, errors, touched }) => (
                    <Box className="resetPasswordModal">
                        <Typography className="addRiderText">Reset Password</Typography>
                        <TextField
                            label="Current Password"
                            id="outlined-size-small"
                            size="small"
                            className="restPasswordText-1"
                            // onChange={e => setUpdatePassword({...updatePassword, old_password: e.target.value})}
                            onChange={handleChange('old_password')}
                            onBlur={handleBlur('old_password')}
                        />
                        <div style={{}}>
                                {
                                    errors.old_password ?
                                        <div>
                                            {
                                                touched.old_password ?
                                                    <Typography style={{ color: 'red', fontSize: '10px' }}>{errors.old_password}</Typography> : <></>
                                            }
                                        </div> :
                                        <div></div>
                                }
                        </div>

                        <TextField
                            label="New Password"
                            id="outlined-size-small"
                            size="small"
                            type="password"
                            className="restPasswordText"
                            onChange={handleChange('password')}
                            onBlur={handleBlur('password')}
                            // onChange={e => setUpdatePassword({...updatePassword, password: e.target.value})}
                        />
                        <div style={{}}>
                                {
                                    errors.password ?
                                        <div>
                                            {
                                                touched.password ?
                                                    <Typography style={{ color: 'red', fontSize: '10px' }}>{errors.password}</Typography> : <></>
                                            }
                                        </div> :
                                        <div></div>
                                }
                        </div>

                        <TextField
                            label="Confirm Password"
                            id="outlined-size-small"
                            size="small"
                            type="password"
                            className="restPasswordText"
                            onChange={handleChange('confirmPassword')}
                            onBlur={handleBlur('confirmPassword')}
                            // onChange={e => setUpdatePassword({...updatePassword, confirmPassword: e.target.value})}
                        />
                        <div style={{}}>
                                {
                                    errors.confirmPassword ?
                                        <div>
                                            {
                                                touched.confirmPassword ?
                                                    <Typography style={{ color: 'red', fontSize: '10px' }}>{errors.confirmPassword}</Typography> : <></>
                                            }
                                        </div> :
                                        <div></div>
                                }
                        </div>

                        <Button variant='contained' sx={{marginTop: '20px'}} disabled={ updatePassword.password !== updatePassword.confirmPassword } onClick={handleSubmit} >Update Password</Button>
                    </Box>
                )}
            </Formik>
        </Modal> */}


        {/* <------------ Update Profile Modal ------------> */}
        <Modal
            open={openProfile}
            onClose={handleProfileClose}
        >
            <Box className="updateProfileModal">
                <Typography className='updateProfileText text-center'>Update Profile</Typography>
                <TextField
                    label="Mobile"
                    id="outlined-size-small"
                    size="small"
                    className="restPasswordText"
                    type="number"
                    required
                    value={clientDetails?.mobile}
                    onChange={(e) => setClientDetails({...clientDetails, mobile: e.target.value})}
                />
                <TextField
                    label="Email"
                    id="outlined-size-small"
                    size="small"
                    className="restPasswordText"
                    required
                    value={clientDetails?.email}
                    onChange={(e) => setClientDetails({...clientDetails, email: e.target.value})}
                />
                <TextField
                    label="Address"
                    id="outlined-size-small"
                    size="small"
                    className="restPasswordText"
                    required
                    value={clientDetails?.address}
                    onChange={(e) => setClientDetails({...clientDetails, address: e.target.value})}
                />
                <TextField
                    label="Pincode"
                    id="outlined-size-small"
                    size="small"
                    type="number"
                    className="restPasswordText"
                    required
                    value={clientDetails?.pincode}
                    onChange={(e) => setClientDetails({...clientDetails, pincode: e.target.value})}
                />
                <TextField
                    label="City"
                    id="outlined-size-small"
                    size="small"
                    className="restPasswordText"
                    required
                    value={clientDetails?.city}
                    onChange={(e) => setClientDetails({...clientDetails , city: e.target.value})}
                />
                <TextField
                    label="State"
                    id="outlined-size-small"
                    size="small"
                    className="restPasswordText"
                    required
                    value={clientDetails?.state}
                    onChange={(e) => setClientDetails({...clientDetails, state: e.target.value})}
                />

                <Button variant='contained' onClick={handleUpdateProfile} sx={{borderRadius: '18px !important'}}>
                    {loading2 && (
                        <CircularProgress
                            className="loader"
                            size={24}
                            style={{ marginLeft: loading2 ? 8 : 0 }}
                        />
                    )}
                    <span style={{ visibility: loading2 ? "hidden" : "visible" }}>Update Profile</span>
                </Button>
            </Box>
        </Modal>

    </div>
  )
}

export default ClientManagement