// import React from 'react';

// function Dashboard() {
//   return (
//     <h1 className='text-center' style={{marginTop: '5%', color: 'GrayText'}}> Welcome To Dashboard</h1>
//   )
// }

// export default Dashboard

import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Grid, Card, CardContent, Typography, Box, Container } from '@mui/material';
import PeopleIcon from '@mui/icons-material/People';
import DirectionsBikeIcon from '@mui/icons-material/DirectionsBike';
import AssignmentIcon from '@mui/icons-material/Assignment';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { API } from '../../utils/Api';
import '../../saas/screens/dashboard.scss';
import { motion } from 'framer-motion';

function Dashboard() {
  const token = localStorage.getItem('token');
  const [loading, setLoading] = useState(false)
  const [metrics, setMetrics] = useState(
  {
    total_riders: '0',
    online_riders: '0',
    active_orders: '0',
    delivered_orders: '0',
  }
);

  const getMetricCounters = async() => {
    try {
      const config = {
          headers : {
              Authorization : `${token}`
          }
      };
      setLoading(true);
      const response = await axios.get(`${API.BASE_URL}client/dashboard/metrics`, config);
      console.log("organization data new",response.data.data);
      setMetrics(response.data.data)
      } 
      catch (error) {
          let errorMessage = "An Error Occurred While Fetching Client's Records";    
      }
  }

  // useEffect(() => {
  //   axios.get('https://devapis.zupzap.com/v1/client/dashboard/metrics')
  //     .then(response => {
  //       if (response.data.status) {
  //         setMetrics(response.data.data);  => 1st card color - background: 'linear-gradient(to right bottom, #6C4298, #C6CDAD)'
  //       }                                  => 2nd card color - background: 'linear-gradient(to right bottom, #81f542, #1E9CED)'
  //     })                                   => 3rd card color - background: 'linear-gradient(to right bottom, #1E9CED, #d7f6f7)'
  //     .catch(error => {                    => 4th card color - background: 'linear-gradient(to right bottom, #fcba03, #1E9CED)'
  //       console.error('There was an error fetching the metrics!', error);
  //     });
  // }, []);    color :#430089      #82ffa1

  useEffect(() => {
    getMetricCounters();
  }, [] );

  return (
    <Box sx={{ marginTop: '8%', textAlign: 'center' }} >
      {/* <Typography variant="h3" gutterBottom>Welcome To Dashboard</Typography> */}
      <Container>

        {/* <------------------- Metric Data Cards -----------F28B3C-----FCBA03--> */}
        <Grid container spacing={4} justifyContent="center">
          <Grid item xs={12} sm={6} md={3} >
            <motion.div 
              initial={{ opacity: 0, y: -150 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5 }}
            >
              <Card className='metricCards' sx={{background: 'linear-gradient(to right bottom, #1E9CED, #f7caa8)', color: 'white'}}>       
                <CardContent>
                  <PeopleIcon sx={{ fontSize: 50 }} />
                  <Typography variant="h5">Total Riders</Typography>
                  <Typography variant="h4">{metrics.total_riders}</Typography>
                </CardContent>
              </Card>
            </motion.div>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <motion.div 
              initial={{ opacity: 0, y: -150 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.62 }}
            >
              <Card className='metricCards' sx={{background: 'linear-gradient(to right bottom, #1E9CED, #b89fd4)', color: 'white'}}>
                <CardContent>
                  <DirectionsBikeIcon sx={{ fontSize: 50 }} />
                  <Typography variant="h5">Online Riders</Typography>
                  <Typography variant="h4">{metrics.online_riders}</Typography>
                </CardContent>
              </Card>
            </motion.div>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <motion.div 
              initial={{ opacity: 0, y: -150 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.75 }}
            >
              <Card className='metricCards' sx={{background: 'linear-gradient(to right bottom, #1E9CED, #bda668)', color: 'white'}}>
                <CardContent>
                  <AssignmentIcon sx={{ fontSize: 50 }} />
                  <Typography variant="h5">Active Orders</Typography>
                  <Typography variant="h4">{metrics.active_orders}</Typography>
                </CardContent>
              </Card>
            </motion.div>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <motion.div 
              initial={{ opacity: 0, y: -150 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.83 }}
            >
              <Card className='metricCards' sx={{ background: 'linear-gradient(to right bottom, #1E9CED, #51e86a)', color: 'white' }}>
                <CardContent>
                  <CheckCircleIcon sx={{ fontSize: 50 }} />
                  <Typography variant="h5">Delivered Orders</Typography>
                  <Typography variant="h4">{metrics.delivered_orders}</Typography>
                </CardContent>
              </Card>
            </motion.div>
          </Grid>
        </Grid>
      
      </Container>
    </Box>
  );
}

export default Dashboard;

