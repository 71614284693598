import { Container, Paper, Table, TableBody, TableCell, TableContainer, TableFooter, TableHead, TextField, TablePagination, TableRow, Typography, InputAdornment, IconButton, Select, InputLabel, MenuItem, FormControl, Box, Modal, Grid, Button, CircularProgress } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DemoContainer, DemoItem } from '@mui/x-date-pickers/internals/demo';
import { DateRangePicker, MobileDatePicker, MultiInputDateRangeField } from '@mui/x-date-pickers-pro';
import React, { useEffect, useRef, useState } from 'react';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import '../../saas/screens/orderManagement.scss';
import { Label } from '@mui/icons-material';
import dayjs from 'dayjs';
import axios from 'axios';
import { API } from '../../utils/Api';
import { toast } from 'react-toastify';
import { CalendarToday } from '@mui/icons-material';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import ReceiptIcon from '@mui/icons-material/Receipt';
import PersonIcon from '@mui/icons-material/Person';
import PhoneIcon from '@mui/icons-material/Phone';
import RoomIcon from '@mui/icons-material/Room';
import LocationOnIcon from '@mui/icons-material/LocationOn';
// import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import { AccessTime } from '@mui/icons-material';
import PaymentIcon from '@mui/icons-material/Payment';
import CurrencyRupeeIcon from '@mui/icons-material/CurrencyRupee';
import InfoIcon from '@mui/icons-material/Info';
import DeliveryDiningIcon from '@mui/icons-material/DeliveryDining';
import BusinessIcon from '@mui/icons-material/Business';
import ConfirmationNumberIcon from '@mui/icons-material/ConfirmationNumber';
import { GoogleMap, LoadScript, MarkerF } from '@react-google-maps/api';
import MapComponent from '../../components/MapComponent';
import { Close } from '@mui/icons-material';

const style = {
  position: 'absolute',
  outline: 'none',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 800,
  bgcolor: 'background.paper',
  boxShadow: 24,
  borderRadius: '12px'
  // p: 4,
};

const trackModalStyle = {
  position: 'absolute',
  outline: 'none',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '100%',
  height: 700,
  bgcolor: 'background.paper',
  boxShadow: 24,
  borderRadius: '12px',
  // p: 4,
};

const mapContainerStyle = {
  height: '500px',
  width: '100%',
};

const center = {
  lat: 0,
  lng: 0,
};

function OrderManagement() {

  const token = localStorage.getItem('token');
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [orderList, setOrderList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [status, setStatus] = useState('');
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [open, setOpen] = useState(false);
  const [orderDetails, setOrderDetails] = useState({});
  const [totalRecords, setTotalRecords] = useState(0);
  const [pickupLatitude, setPickupLatitude] = useState('');
  const [pickupLongitude, setPickupLongitude] = useState('');
  const [dropLatitude, setDropLatitude] = useState('');
  const [dropLongitude, setDropLongitude] = useState('');
  const [openMap, setopenMap] = useState(false);
  const [orderID, setOrderID] = useState('');
  const isInitialMount = useRef(true);
  const [initialCall, setInitialCall] = useState(true);

  const handleClose = () => setOpen(false);

  const columns = [
    {
      id: "sr_no", label: "Sr.No"
    },
    {
      id: "order_no", label: "Order No"
    },
    {
      id: "rider_name", label: "Rider Name"
    },
    {
      id: "pickup_name", label: "Pickup Name"
    },
    {
      id: "pickup_address", label: "Pickup Address"
    },
    {
      id: "order_amount", label: "Order Amount", align: 'center'
    },
    {
      id: "payment_mode", label: "Payment Mode", align: 'center'
    },
    {
      id: "status", label: "Status", align: 'center', minWidth: 150
    },
    {
      id: "actions", label: "Actions", align: 'center'
    }
  ]

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(1);
  };

  const handleStatusChange = (event) => {
    const value = event.target.value;
    setStatus(value);
  };

  const handleStartDateChange = (date) => {
    setStartDate(date.format('DD-MM-YYYY'));
    console.log("Selected Start Date:", date.format('DD-MM-YYYY'));
  };
  
  const handleEndDateChange = (date) => {
    setEndDate(date.format('DD-MM-YYYY'));
    console.log("Selected End Date:", date.format('DD-MM-YYYY'));
  }

  const getOrderData = async () => {
    try {
      const config = {
        headers: { Authorization: `${token}` }
      };
      setLoading(true);
      const requestBody = {
        "status": status,
        "date_range": {
          "start_date": startDate,
          "end_date": endDate,
        },
        "pagination": {
          "page": page + 1,
          "limit": rowsPerPage
        }
      };
      console.log(requestBody);
      const response = await axios.post(`${API.BASE_URL}client/orders/list`, requestBody, config);
      console.log("Fetching orders", response.data.data);
      response?.data?.data?.list.forEach((item, index) => {
        console.log("page :", page, "Index :", index);
        if (page == 0) {
          item.serialNumber = index + 1
        } else {
          item.serialNumber = (page) * 10 + (index + 1);
        }
        
      });
      setOrderList(response.data.data.list);
      setTotalRecords(response.data.data.total_records);
      setLoading(false);
    } catch (error) {
      const formError = error.response.data.data.form_error;
      const hasHtmlTags = /<[^>]*>/.test(formError);

      let cleanError;
      if (hasHtmlTags) {
        cleanError = formError.replace(/<[^>]*>/g, ''); // Remove HTML tags
        toast.error(cleanError, { position: "top-right", autoClose: 3000, theme: "colored" });
      } else {
        cleanError = error.response.data.data.message;
        toast.error(cleanError, { position: "top-right", autoClose: 3000, theme: "colored" });
      }
      setOrderList([]);
      setLoading(false);
    }
  };
  
  const handleMapClose = () => setopenMap(false);

  const handleOpen = async (orderData) => {

    let orderId = orderData?.id;

    console.log("order data:",orderId);

    try {
      const config = {
        headers: { Authorization: `${token}` }
      };
      const response = await axios.get(`${API.BASE_URL}client/orders/single?order=${orderId}`, config);
      console.log("hello res :", response.data.data.order);
      let singleOrderData = response?.data?.data?.order;
      setOpen(true);
      setOrderDetails(singleOrderData);
      if (singleOrderData.pickup_coordinates) {
        const [lat, lng] = singleOrderData.pickup_coordinates.split(',');
        console.log("pickup:", lat, lng);
        setPickupLatitude(parseFloat(lat.trim()));
        setPickupLongitude(parseFloat(lng.trim()));
      }
      if (singleOrderData.drop_coordinates) {
        const [lat, lng] = singleOrderData.drop_coordinates.split(',');
        setDropLatitude(parseFloat(lat.trim()));
        setDropLongitude(parseFloat(lng.trim()));
        console.log("drop:", lat, lng);

      }
    } catch (error) {
      
    }
  };

  const handleOpenMapTrack = (orderDetails) => {
    console.log("orderDetails", orderDetails.id);
    let orderId = orderDetails;
    setOrderID(orderId);
    setopenMap(true);
  }

  // Separate Date and Time
  const [date, time, amPm] = (orderDetails.date_time ?? '').split(' ');

  const details = [
    { label: 'Order No', value: orderDetails.order_no, icon: <ReceiptIcon /> },
    { label: 'Rider', value: orderDetails.rider_name, icon: <DeliveryDiningIcon /> },
    { label: 'Customer', value: orderDetails.customer_name, icon: <PersonIcon /> },
    { label: 'Customer Contact', value: orderDetails.customer_contact, icon: <PhoneIcon /> },
    { label: 'Pickup Address', value: orderDetails.pickup_address, icon: <RoomIcon /> },
    { label: 'Drop Address', value: orderDetails.drop_address, icon: <LocationOnIcon /> },
    // { label: 'Pickup Coordinates', value: orderDetails.pickup_coordinates, icon: <LocationOnIcon /> },CalendarTodayIcon
    // { label: 'Drop Coordinates', value: orderDetails.drop_coordinates, icon: <LocationOnIcon /> },AccessTimeIcon
    { label: 'Order Amount', value: `₹${orderDetails.order_amount}`, icon: <CurrencyRupeeIcon /> },
    { label: 'Payment Mode', value: orderDetails.payment_mode, icon: <PaymentIcon /> },
    { label: 'Sr.No', value: orderDetails.sr_no, icon: <ConfirmationNumberIcon /> },
    { label: 'Status', value: orderDetails.status, icon: <InfoIcon /> },
    { label: 'Date', value: date, icon: <CalendarToday /> },
    { label: 'Time', value: `${time} ${amPm}` , icon: <AccessTime  /> }
  ];

  const center = {
    lat: pickupLatitude || 0, // Set a default value if latitude is null
    lng: pickupLongitude || 0, // Set a default value if longitude is null
  };


  useEffect(() => {
    console.log("initialCall :", initialCall);    
    if (initialCall) {
      setInitialCall(false);
    } else {
      getOrderData();
    }
  }, [page, rowsPerPage]);

  return (
    <div className='ordersMain'>
        <Container maxWidth="xl">
          <div className='d-flex' style={{ marginBottom: '20px ', justifyContent:'space-between' }}>
                <FormControl sx={{backgroundColor:'white', width:'16%', borderRadius: '11px'}}>
                  <InputLabel id="demo-simple-select-label">Status</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    size='medium'
                    value={status}
                    sx={{width:'100%', borderRadius: '11px'}}
                    label="Age"
                    onChange={handleStatusChange}
                  >
                    <MenuItem value={'DELIVERED'}>DELIVERED</MenuItem>
                    <MenuItem value={'CANCELLED'}>CANCELLED</MenuItem>
                    <MenuItem value={'ON-THE-WAY'}>ON-THE-WAY</MenuItem>
                    <MenuItem value={'PENDING'}>PENDING</MenuItem>
                    <MenuItem value={'PICKED-UP'}>PICKED-UP</MenuItem>
                    <MenuItem value={'RETURNED'}>RETURNED</MenuItem>
                  </Select>
                </FormControl>
                <LocalizationProvider dateAdapter={AdapterDayjs} >
                    <Box className='d-flex'>
                      <DemoItem sx={{ marginRight: '15px' }}>
                        <MobileDatePicker onChange={handleStartDateChange} label="Select Start Date" sx={{ backgroundColor: 'white', borderRadius: '4px' }} />
                      </DemoItem>
                      <DemoItem>
                        <MobileDatePicker onChange={handleEndDateChange} label="Select End Date" sx={{ backgroundColor: 'white',  borderRadius: '4px' }}/>
                      </DemoItem>
                    </Box>
                </LocalizationProvider>
                <Button variant='contained' onClick={() => getOrderData(startDate, endDate)} sx={{width:'15%', borderRadius:'20px', backgroundColor:'#1E9CED'}}>Submit</Button>
          </div>

            {/*<---------------- Order Records Listing ----------------> */}
            <Paper className='ordersList'>
              <Box className='orderRecordSection'>
                <Typography className='orderRecordText'>ORDER RECORDS</Typography>
              </Box>

              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow>
                      { 
                      columns.map((column) => (
                        <TableCell
                          key={column.id}
                          align={column.align}
                          style={{ minWidth: column.minWidth, fontWeight: 700 }}
                        >
                          {column.label}
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  {/* {rows.length !==0 && ( */}
                    <TableBody>
                      { loading ? (
                        <div style={{display: 'flex', justifyContent: 'center', marginTop: '20px', marginBottom: '20px' }}>
                          {/* <CircularProgress /> */}
                        </div>
                      ) : (
                      
                        orderList.map((orders, ind) => (
                          <TableRow>
                              <TableCell>{orders?.serialNumber}</TableCell>
                              <TableCell>{orders?.order_no}</TableCell>
                              <TableCell>{orders?.rider_name}</TableCell>
                              <TableCell>{orders?.pickup_name}</TableCell>
                              <TableCell>{orders?.pickup_address}</TableCell>
                              <TableCell className='text-center'>{orders?.order_amount}</TableCell>
                              <TableCell className='text-center'>{orders?.payment_mode}</TableCell>
                              <TableCell className='text-center'>{orders?.status}</TableCell>
                              <TableCell>
                                <span className='d-flex'>
                                  <a className='aTag' onClick={() => handleOpen(orders)} >
                                    <span className='viewText'>View</span><RemoveRedEyeIcon aria-label="view" color="success" style={{cursor: 'pointer', marginRight: '3px' }} />
                                  </a> |
                                  <a className='aTag' onClick={() => handleOpenMapTrack(orders)} >
                                    <span className='trackText'>Track</span><LocationOnIcon aria-label="view" color='error' style={{cursor: 'pointer', marginRight: '3px' }} />
                                  </a>
                                </span>
                              </TableCell>
                          </TableRow>
                        ) ))}
                      
                    </TableBody>
                  {/* )} */}

                </Table>
                { loading && (
                  <div style={{display: 'flex', justifyContent: 'center'}}>
                    <CircularProgress />
                  </div>
                ) }

                { loading || orderList.length === 0 && (
                  <Typography className='text-center noRecordsFound'>No Records Found</Typography>
                ) }
                <TablePagination
                  sx={{borderTop: orderList.length == 0 ? '2px solid red' : 'none'}}
                  rowsPerPageOptions={[10, 20, 30]}
                  component="div"
                  count={totalRecords}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={(event, newPage) => setPage(newPage)}
                  onRowsPerPageChange={(event) => {
                    setRowsPerPage(parseInt(event.target.value, 10));
                    setPage(0);
                  }}
                />
              </TableContainer>
            </Paper>

            {/* <---------------- View Modal -------------> */}
            <Modal open={open} onClose={handleClose}>
              <Container sx={style} className='viewContainer'>
                <div className='viewOrderDetailsSec' style={{ backgroundColor: '#1E9CED', marginLeft: '-24px', marginRight: '-24px', marginBottom: '20px', borderTopLeftRadius: '12px', borderTopRightRadius: '12px' }}>
                  <Typography variant="h6" component="h2" p={2} sx={{fontWeight: '600', color: 'white', fontSize: '20px'}} > VIEW ORDER DETAILS </Typography>
                </div>

                  <Grid container spacing={2}>
                    {details.map((item, index) => (
                      <Grid item xs={12} sm={6} key={index}>
                        <Paper
                          elevation={3}
                          sx={{
                            p: 2,
                            display: 'flex',
                            alignItems: 'center',
                            transition: 'transform 0.2s',
                            '&:hover': {
                              transform: 'scale(1.05)',
                              backgroundColor: '#f0f0f0',
                            },
                            backgroundColor: '#fafafa',
                          }}
                        >
                          {React.cloneElement(item.icon, { sx: { mr: 1, color: 'primary.main' } })}
                          <Typography variant="body1" sx={{ fontWeight: 'bold', color: 'text.secondary' }}>
                            {item.label}:
                          </Typography>
                          <Typography variant="body1" sx={{ ml: 1, color: 'GrayText' }}>
                            {item.value}
                          </Typography>
                        </Paper>
                      </Grid>
                      
                    ))}
                  </Grid>

                  {/* <----------------- Map Display In View Modal -------------------> */}
                  <div style={{marginTop:'20px', marginBottom: '10px', backgroundColor:'#1E9CED', borderRadius:'18px', padding:'4px'}}>
                    <Typography className='text-center' sx={{fontSize:'18px', fontWeight: 600, color:'white'}}>Pickup & Drop Co-Ordinates</Typography>
                  </div>
                  <LoadScript googleMapsApiKey='AIzaSyATjJP7Zx7lNzU-dRY9dLNTY29TfUHXPJo'>
                    <GoogleMap
                      mapContainerStyle={mapContainerStyle}
                      center={center}
                      zoom={14}
                    >
                      {/* <MarkerF position={{ lat: 15.40345247101363, lng : 73.82391235628559 }} label="P" /> */}
                      {/* <MarkerF position={{ lat: 14.40345247101363, lng : 72.82391235628559 }} label="D" /> */}

                      <MarkerF position={{ lat: pickupLatitude, lng : pickupLongitude }} label="P" />
                      <MarkerF position={{ lat: dropLatitude, lng : dropLongitude }} label="D" />

                    </GoogleMap>
                  </LoadScript>

                  <Box sx={{ mt: 3, display: 'flex', justifyContent: 'flex-end', mb: 2 }}>
                    <Button variant="contained" color="primary" onClick={handleClose} sx={{ width: '200px', backgroundColor: '#1E9CED', borderRadius: '20px ' }}>
                      Close
                    </Button>
                  </Box>
              </Container>
            </Modal>

            {/* <--------------------- Track Order Modal ------------------> */}
            <Modal open={openMap} onClose={handleMapClose}>
                  <Container sx={trackModalStyle}>
                    <div>
                      <div style={{ backgroundColor: '#1E9CED', marginRight: '-24px', marginLeft: '-24px', borderTopRightRadius: '11px', borderTopLeftRadius: '11px', marginBottom: '20px' }}>
                        <Typography variant="h6" component="h2" p={2} sx={{fontWeight: '600', color: 'white', fontSize: '24px'}} > TRACK RIDER </Typography>
                      
                        <IconButton 
                          aria-label="close" 
                          onClick={handleMapClose} 
                          sx={{ position: 'absolute', right: 8, top: 8, color: 'white' }}
                        >
                          <Close sx={{ fontSize: '35px'}} />
                        </IconButton>
                      </div>
                      <MapComponent orderDetails={orderID} />
                    </div>
                  </Container>
            </Modal>

        </Container>
    </div>
  )
}

export default OrderManagement