import React from "react";
import { Routes, Route } from "react-router-dom";
import AppLayout from "./AppLayout";
import LogIn from "./screens/Auth/Login";
import ClientManagement from "./screens/App/ClientManagement";
import ApiManagement from "./screens/App/ApiManagement";
import Dashboard from "./screens/App/Dashboard";
import RiderManagement from "./screens/App/RiderManagement";
import OrganizationSettings from "./screens/App/OrganizationSettings";
import OrderManagement from "./screens/App/OrderManagement";

const AppRoutes = () => {
    return (
        <Routes>
            <Route path="/" element={<LogIn />} />
            <Route path="" element={<AppLayout />}>
                <Route path="/dashboard" element={<Dashboard />} />
                <Route path="/clientManagement" element={<ClientManagement />} />
                <Route path="/apikeys" element={<ApiManagement />} />
                <Route path="/riderManagement" element={<RiderManagement />} />
                <Route path="/orderManagement" element={<OrderManagement />} />
                <Route path="/organizationSettings" element={<OrganizationSettings />} />
            </Route>
        </Routes>
    )
}

export default AppRoutes