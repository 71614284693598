import React, { useState } from 'react';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import { Typography } from '@mui/material';

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const CopySnackbar = ({ open, handleClose }) => {
  return (
    <Snackbar open={open} autoHideDuration={3000} onClose={() => handleClose(false)} message="Copied to clipboard!" >
      {/* <Alert onClose={handleClose} severity="success">
        Copied to clipboard!
      </Alert> */}
        {/* Copied to clipboard! */}
    </Snackbar>
  );
};

export default CopySnackbar;
