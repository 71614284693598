import React, { useEffect, useState } from 'react';
import { Button, Container, Paper, TextField, Typography } from '@mui/material';
import { Formik } from 'formik';
import * as Yup from 'yup';
import axios from 'axios';
import { API } from '../../utils/Api';
import { toast } from 'react-toastify';
import '../../saas/screens/organizationSettings.scss';

function OrganizationSettings() {

    const token = localStorage.getItem('token');
    const [loading, setLoading] = useState(false);
    const [settingsForm, setSettingsForm] = useState({
        pickup_meters: '0',
        drop_meters: '0'
    })

    const validationSchema = Yup.object({
        pickup_meters: Yup.string()
            .required("Pickup meters is required")
            .trim()
            .matches(/^[0-9]+$/, 'Pickup meters must contain only numeric values'),
            // .integer("Pick-Up Meters must be an integer")
            // .positive("Pick-Up Meters must be positive"),
        drop_meters: Yup.string()
            .required('Pickup meters field is required')
            .trim()
            .matches(/^[0-9]+$/, 'Drop meters must contain only numeric values')
            // .integer("Drop Meters must be an integer")
            // .positive("Drop Meters must be positive")
    })

    const getOrganizationData = async () => {
        try {
            const config = {
                headers : {
                    Authorization : `${token}`
                }
            };
            setLoading(true);
            const response = await axios.get(`${API.BASE_URL}client/settings`, config);
            console.log("organization data new",response.data.data.settings);
            setSettingsForm(response.data.data.settings);
        } catch (error) {
            let errorMessage = "An Error Occurred While Fetching Client's Records";    
        }

    }

    const handleSubmitOrg = async (values) => {
        console.log("values", values);
        let updatedOrgSettings = {
            pickup_meters: values.pickup_meters,
            drop_meters: values.drop_meters
        }
        try {
            const response = await axios.post(`${API.BASE_URL}client/settings/update`, updatedOrgSettings, {
                headers: {
                    Authorization: `${token}`,
                },
            } )
            getOrganizationData();
            setLoading(false);
            toast.success("Settings Updated Successfully"); 
        } catch (error) {
            console.log("Error updating the settings", error);
            setLoading(false);
            toast.error("Failed to update the settings")
        }
    }

    useEffect(() => {
        getOrganizationData();
    },[] )
  
    return (

        <div className='settingsMain'>

            {/* <---------------- Organization Settings Form -----------------> */}
            <Formik initialValues={settingsForm} onSubmit={handleSubmitOrg} validationSchema={validationSchema} enableReinitialize={true}>
                {({ handleChange, handleBlur, handleSubmit, values, errors, touched }) => (
                    <Container maxWidth="xl">
                        <Paper className='settingsSection'>
                            <div className='orgSettingsSection'>
                                <Typography sx={{ fontWeight:'600', fontSize:'18px', color: 'white' }}>ORGANIZATION SETTINGS</Typography>
                            </div>
                            <Container>
                                <div className='settingsTextSection'>
                                    <TextField
                                        className='settingsTextField'
                                        label="Pick-Up Meters"
                                        size='small'
                                        type='number'
                                        id='outlined-size-small'
                                        value={values?.pickup_meters}
                                        onChange={handleChange('pickup_meters')}
                                        onBlur={handleBlur('pickup_meters')}
                                        fullWidth
                                        required
                                        inputProps={{ min: 0 }}
                                    />
                                    <div style={{}}>
                                        {
                                            errors.pickup_meters ?
                                                <div>
                                                    {
                                                        touched.pickup_meters ?
                                                            <Typography style={{ color: 'red', fontSize: '10px' }}>{errors.pickup_meters}</Typography> : <></>
                                                    }
                                                </div> :
                                                <div></div>
                                        }
                                    </div>

                                    <TextField
                                        className='settingsTextField'
                                        label="Drop Meters"
                                        size='small'
                                        type='number'
                                        id='outlined'
                                        value={values?.drop_meters}
                                        onChange={handleChange('drop_meters')}
                                        onBlur={handleBlur('drop_meters')}
                                        fullWidth
                                        required
                                        inputProps={{ min: 0 }}
                                    />
                                    <div style={{}}>
                                        {
                                            errors.drop_meters ?
                                                <div>
                                                    {
                                                        touched.drop_meters ?
                                                            <Typography style={{ color: 'red', fontSize: '10px' }}>{errors.drop_meters}</Typography> : <></>
                                                    }
                                                </div> :
                                                <div></div>
                                        }
                                    </div>

                                    <div className='settingsButtonSection'>
                                        <Button onClick={handleSubmit} variant='contained' sx={{ borderRadius:'20px', backgroundColor:'#1E9CED', width:'100%' }}>
                                            Update Settings
                                        </Button>
                                    </div>
                                </div>
                            </Container>
                        </Paper>
                    </Container>
                )}
            </Formik>

        </div>
    )
}

export default OrganizationSettings