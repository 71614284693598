import React from "react";
import {
  GoogleMap,
  LoadScript,
  MarkerF,
  Polyline,
  PolylineF,
} from "@react-google-maps/api";
import { useEffect, useState } from "react";
import axios from "axios";
import { API } from "../utils/Api";
import { toast } from "react-toastify";

const containerStyle = {
  width: "100%",
  height: "600px",
};

const center = {
  lat: parseFloat(15.489782804994276),
  lng: parseFloat(73.81988311130398),
};

//   const pathCoordinates = [
//     { lat: 15.489782804994276, lng: 73.81988311130398 },
//     { lat: 15.489071979346278, lng: 73.81926291170065 },
//     { lat: 15.488569996664117, lng: 73.81904443237029 },

//     { lat: 15.486704807724161, lng: 73.81762967676455 },
//     { lat: 15.485128058736844, lng: 73.81676064104919 },
//     { lat: 15.483912324285681, lng: 73.816414712027249 },
//     // Add more coordinates as needed
//   ];

const options = {
  strokeColor: "#FF0000",
  strokeOpacity: 0.8,
  strokeWeight: 7,
  clickable: false,
  draggable: false,
  editable: false,
  geodesic: false,
  zIndex: 1,
};

function MapComponent({ orderDetails }) {
  let orderID = orderDetails?.id;
  let orderDetailStatus = orderDetails?.status;
  console.log("orderDetailStatus",orderDetailStatus);
  console.log("order order id", orderID);
  console.log(" check me",orderDetails);
  const token = localStorage.getItem("token");
  const [pathCoordinates, setPathCoordinates] = useState([]);
  const [riderCurrentLocation, setRiderCurrentLocation] = useState({
    lat: '',
    lng: ''
  })

  console.log(orderID, "hai");
  const getOrderTrack = async () => {
    try {
      const config = {
        headers: {
          Authorization: `${token}`,
        },
      };
      const response = await axios.get(
        `${API.BASE_URL}client/orders/tracking?order=${orderID}`,
        config
      );
      console.log("tracking order response is: ",response);
      if (response.data.status === true) {
        console.log("organization Data:", response.data.data.track);
        const someData = response?.data?.data?.track;
        const coordinates = someData.map((co) => ({
          lat: parseFloat(co.latitude),
          lng: parseFloat(co.longitude),
        }));
        const currentData = response?.data?.data?.current;
        setRiderCurrentLocation({
          lat: parseFloat(currentData?.latitude),
          lng: parseFloat(currentData?.longitude)
        })
        setPathCoordinates(coordinates);
      } else {
        toast.error(response.data.message, {
          position: "top-right",
          autoClose: 3000,
          theme: "colored",
        });
      }
    } catch (error) {
      let errorMessage = "An error occurred while fetching client's products.";
      if (error.response && error.response.data) {
        errorMessage = error.response.data.message;
      }
      toast.error(errorMessage, {
        position: "top-right",
        autoClose: 3000,
        theme: "colored",
      });
    }
  };

  useEffect(() => {
    console.log('fetching coordinates');
    getOrderTrack();
  }, [orderID]);


  console.log("The path coordinates are: ",pathCoordinates);
  return (
    <LoadScript googleMapsApiKey="AIzaSyATjJP7Zx7lNzU-dRY9dLNTY29TfUHXPJo">
      { orderDetailStatus === "DELIVERED" ?
        <GoogleMap
          mapContainerStyle={containerStyle}
          center={pathCoordinates[0]}
          zoom={16}
        >
          <PolylineF path={pathCoordinates} options={options} />
          <MarkerF position={pathCoordinates[0]} label="P"/>
          <MarkerF position={pathCoordinates[pathCoordinates.length-1]} label="D"/>
        </GoogleMap> 
      : 
        <GoogleMap
          mapContainerStyle={containerStyle}
          center={riderCurrentLocation}
          zoom={16}
        >
          {/* <PolylineF path={pathCoordinates} options={options} /> */}
          <MarkerF position={riderCurrentLocation} label="C"/>
          <PolylineF path={pathCoordinates} options={options} />
          <MarkerF position={pathCoordinates[0]} label="S"/>
        </GoogleMap> 
      }
    </LoadScript>
  );
}

export default MapComponent;
