import logo from './logo.svg';
import './App.css';
import { BrowserRouter } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import "react-toastify/ReactToastify.css";
import AppRoutes from './AppRoutes';
import './saas/main.scss'

function App() {
  return (
    <>
      <BrowserRouter>
        <AppRoutes />
      </BrowserRouter>
      <ToastContainer position='top-right' autoClose={5000} theme="colored" pauseOnHover={false} />
    </>
  );
}

export default App;
