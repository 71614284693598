import { Paper, Table, TableContainer, TableHead, TableRow, TableCell, Typography, Button, TableBody, CircularProgress, TableFooter, Tooltip, Container } from '@mui/material';
import React, { useEffect, useState } from 'react';
import '../../saas/screens/apiManagement.scss'
import { API } from '../../utils/Api';
import axios from 'axios';
import { toast } from 'react-toastify';
import { generatePath } from 'react-router-dom';
import CopySnackbar from '../../components/CopySnackbar';
// import { FileCopy as FileCopyIcon } from '@material-ui/icons';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import AddIcon from '@mui/icons-material/Add';
import Swal from 'sweetalert2';
import { motion } from 'framer-motion';

function ApiManagement() {

  const token = localStorage.getItem("token");
  const [apiList, setApiList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [copySuccess, setCopySuccess] = useState(false);

  const columns = [
    { id: "Sr.No", label: "Sr No", minWidth: 80 },
    { id: "api_secret", label: "Api_Secret", minWidth: 200 },
    { 
        id: "api_key",
        label: "Api_Key",
        minWidth: 200,
        align: "left",
        format: (value) => value.toLocalString("en-US")
    },
    {
        id: "status",
        label: "Status",
        minWidth: 150,
        align: "left",
        format: (value) => value.toLocalString("en-US")
    },
  ]

  const getApiData = async () => {
    try {
      const config = {
        headers : {
            Authorization : `${token}`
        }
      };
      setLoading(true)
      const response = await axios.get(`${API.BASE_URL}api-keys/list`, config);
      if(response.data.status === true) {
          console.log("api response:", response.data.data.list);
          setLoading(false);
          setApiList(response.data.data.list);
      } else { 
          toast.error(response.data.message, {
              position: "top-right",
              autoClose: 3000,
              theme: 'colored'
          })
      }
    } catch (error) {
      setLoading(false);
    }
  }

  const generateNewApiKey = async () => {
    Swal.fire({
      title: "Are You Sure?",
      text: "If you generate new key, the previous key will get deactivated",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!"
    }).then(async (result) => {
      if (result.isConfirmed) {

        try {
          const config = {
            headers : {
                Authorization : `${token}`
            }
          };
          const response = await axios.post(`${API.BASE_URL}api-keys/create`,null, config);
          if(response.data.status === true) {
            getApiData();
            toast.success("New API key generated successfully, your old API key is set inactive immediately.");
          } else { 
              toast.error(response.data.message, {
                  position: "top-right",
                  autoClose: 3000,
                  theme: 'colored'
              })
          }
        } catch (error) {
          console.log("Error: ", error);
        }
      }
    })
  }

  // const handleCopyToClipboard = (text) => {
  //   navigator.clipboard.writeText(text)
  //     .then(() => {
  //       console.log('Text copied to clipboard:', text);
  //       setCopySuccess(true);
  //       setTimeout(() => {
  //         setCopySuccess(false);
  //       }, 3000);
  //       // Optionally, you can show a notification or perform any other action upon successful copy
  //     })
  //     .catch((error) => {
  //       console.error('Failed to copy text to clipboard:', error);
  //       // Optionally, you can handle errors here, e.g., show an error message to the user
  //     });
  // };

  const handleCopyToClipboard = (text) => {
    if (navigator.clipboard && navigator.clipboard.writeText) {
      navigator.clipboard.writeText(text)
        .then(() => {
          console.log('Text copied to clipboard:', text);
          setCopySuccess(true);
          setTimeout(() => {
            setCopySuccess(false);
          }, 3000);
          // Optionally, you can show a notification or perform any other action upon successful copy
        })
        .catch((error) => {
          console.error('Failed to copy text to clipboard:', error);
          // Optionally, you can handle errors here, e.g., show an error message to the user
        });
    } else {
      console.error('Clipboard API not supported or not accessible.');
      // Optionally, you can show an error message to the user or handle this case differently
      alert('Clipboard API is not supported on this browser or context.');
    }
  };

  useEffect( ()=> {
    getApiData();
  }, [])
  

  return (
    <div className='apiMain'>
        {/* <h1 className='text-center' style={{marginTop: '5%', color: 'grey'}}>API Management</h1> */}

      <Container maxWidth="xl">
        <motion.div 
          initial={{ opacity: 0, y: -150 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
        >
          <div className='text-end' style={{marginBottom:'10px'}}>
            <Button size='large' variant='contained' sx={{ backgroundColor: 'green', marginBottom: '10px'}} onClick={generateNewApiKey} > <AddIcon sx={{}}/> Generate New Api Key</Button>
          </div>
        </motion.div>

        <Paper className='apiListTable'>

          <div className='' style={{ backgroundColor: '#1E9CED', paddingBottom: '5px', borderTopLeftRadius: '12px', borderTopRightRadius: '12px' }}>
            {/* <Button size='small' variant='contained' sx={{borderRadius: '20px', backgroundColor: 'green'}} onClick={generateNewApiKey} >Generate New Api Key</Button> */}
            <Typography className='listApiText'>List of API'S</Typography>
          </div>

          <TableContainer>
            <Table stickyHeader aria-label='sticky table'>
              <TableHead>
                <TableRow>
                    {columns.map((column) => (
                                  <TableCell
                                      key={column.id}
                                      align={column.align}
                                      style={{ minWidth: column.minWidth, fontWeight: 700 }}
                                  >
                                      {column.label}
                                  </TableCell>
                    ) )}
                </TableRow>
              </TableHead>

              {/* <TableBody>
                  { loading ? (
                      <CircularProgress className='tableLoader' />
                  ) : (
                      apiList?.map( (api) => (
                          <TableRow>
                              <TableCell style={{ color: api.is_active == 1 ? 'black' : 'grey' }}>{api.api_secret}</TableCell>
                              <TableCell style={{ color: api.is_active == 1 ? 'black' : 'grey' }}>{api.api_key}</TableCell>
                              <TableCell style={{ color: api.is_active == 1 ? 'green' : 'red' }} >
                                { api.is_active == 1 ? 'Active' : 'Inactive' }
                              </TableCell>
                          </TableRow>
                      ))
                  )}
              </TableBody> */}

              <TableBody>
                {loading ? (
                  <CircularProgress className='tableLoader' />
                ) : (
                  apiList?.map((api, ind) => (
                    <TableRow key={api.id}>
                      <TableCell> 
                        {ind+1}
                      </TableCell>
                      <TableCell
                        style={{ cursor: 'pointer', color: api.is_active == 1 ? 'black' : 'grey' }}
                        onClick={() => handleCopyToClipboard(api.api_secret)}
                        className="copy-to-clipboard" // Add a class for styling or other purposes
                      >
                        <Tooltip title={<span style={{ display: 'flex', alignItems: 'center' }}><ContentCopyIcon style={{ marginRight: '4px' }} />Click to Copy to clipboard</span>} arrow>
                          {api.api_secret}
                        </Tooltip>
                      </TableCell>
                      <TableCell
                        style={{ cursor: 'pointer', color: api.is_active == 1 ? 'black' : 'grey' }}
                        onClick={() => handleCopyToClipboard(api.api_key)}
                        className="copy-to-clipboard" // Add a class for styling or other purposes
                      >
                        <Tooltip title={<span style={{ display: 'flex', alignItems: 'center' }}><ContentCopyIcon style={{ marginRight: '4px' }} />Click to Copy to clipboard</span>} arrow>
                          {api.api_key}
                        </Tooltip>
                      </TableCell>
                      <TableCell style={{ color: api.is_active == 1 ? 'green' : 'red', cursor: 'default' }}>
                        {api.is_active == 1 ? 'Active' : 'Inactive'}
                      </TableCell>
                    </TableRow>
                  ))
                )}
                
              </TableBody>

              { loading || apiList.length === 0 && (
                <Typography className='text-center' sx={{ fontSize: '20px', marginTop: '20px', fontWeight: '600', marginBottom: '10px', color: 'red' }}>No Records Found</Typography>
              ) }

              <TableFooter>
                <Typography className='text-center' style={{color: 'white'}}>h</Typography>
              </TableFooter>

            </Table>
          </TableContainer>
        </Paper>
      </Container>

{
  copySuccess &&
  <CopySnackbar open={copySuccess} handleClose={ setCopySuccess } />
}

    </div>
  )
}

export default ApiManagement