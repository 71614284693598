const Images = {
    signImage: require('../assets/images/signImage.png'),
    stockLogo: require('../assets/images/stockLogo.png'),
    
    profileSmall: require('../assets/images/profileSmall.png'),
    profileSmallWhite: require('../assets/images/profileSmallWhite.png'),

    clientPanel: require('../assets/images/ClientPanel.png'),
    stockHead: require('../assets/images/stockHead.png'),
    bexLogo: require('../assets/images/bexLogo.png'),
    bellIcon: require('../assets/images/bell-icon.png'),
    bellIconMob: require('../assets/images/bell-iconMob.png'),

    product: require('../assets/images/product.png'),
    addProduct: require('../assets/images/addProduct.png'),
    xlsGrey: require('../assets/images/xlsGrey.png'),
    xlsGreen: require('../assets/images/xlsGreen.png'),

}

export default Images;